import React, { useRef } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/images/logo.gif";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";

import ProviderLogo1 from "../assets/images/providers/evolution.jpg";
import ProviderLogo2 from "../assets/images/providers/netent.png";
import ProviderLogo3 from "../assets/images/providers/ezugi.jpg";
import ProviderLogo4 from "../assets/images/providers/redtiger.png";
import ProviderLogo5 from "../assets/images/providers/spribe.png";
import { Col, Container, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom/dist";

import Email from "../assets/images/icons/email.png";
import MenuIcon from "../assets/images/icons/user.png";
import CasinoIcon from "../assets/images/icons/Exchange.png";
import DepositIcon from "../assets/images/icons/deposit.png";
import EzugiIcon1 from "../assets/images/icons/Ezugi.png";

import SportsIcon1 from "../assets/images/icons/throw.png";
import LogInIcon from "../assets/images/icons/login-white.png";

import SupportIcon from "../assets/images/icons/Support-white.png";
import WalletIcon1 from "../assets/images/icons/wallet.png";
import { handleRightSideBar } from "../redux/app/actions";
import { useDispatch } from "react-redux";
import AppDownload from "../assets/images/icons/download.png";
import ezugi_icon from "../assets/images/icons/ezugu_icon.gif";
import GamesSearchModal from "../views/components/GamesSearchModal";
import TawkChat from "../views/components/TawkChat";
import SupportIcon1 from "../assets/images/icons/customer-service.png";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const tawkMessengerRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const { showRightSideBar } = useSelector((state) => state.app);
  const [activePage, setActivePage] = useState("");
  const isApk = localStorage.getItem("isApk") === "true";

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "gatewaylist",
  ];

  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const handleSupportClick = () => {
    tawkMessengerRef.current?.toggle();
  };
  return (
    <footer className="footer">
      <Container>
        <div className="email">
          <h6>Email us </h6>
          <a href="mailto:Support@xcasinoworld.com">
            <img src={Email} alt="email" />
            <span>Support@xcasinoworld.com</span>
          </a>
        </div>

        <div className="footer-section">
          <div className="footer-left-part">
            <div className="footer-menu-web">
              <ul>
                <li>
                  <a href="/contactus">Contact US</a>
                </li>
                <li>
                  <a href="/privacypolicy">Privacy</a>
                </li>
                <li>
                  <a href="/responsiblegames">Responsible Gaming</a>
                </li>
                <li>
                  <a href="/selfexclusion">Self Exclusion</a>
                </li>
                <li>
                  <a href="/payout">Payout</a>
                </li>

                <li>
                  <a href="/disputeresolution">Dispute Resolution</a>
                </li>

                <li>
                  <a href="/registration">Registration</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-content">
          <div className="certifiedSec">
            <div className="providers_img">
              <a href="javascript:void(0)">
                <img src={RNG} loading="lazy" alt="RNG Certified" />
              </a>
              <a href="javascript:void(0)">
                <img src={Gambling} loading="lazy" alt="Gambling Commission" />
              </a>
              <a href="javascript:void(0)">
                <img
                  src={ResponsibleG}
                  loading="lazy"
                  alt="Responsible Gaming"
                />
              </a>
              <a href="javascript:void(0)">
                <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
              </a>
            </div>
          </div>
          <p>
            In order to register for this website, the user is required to
            accept the <a href="/terms">General Terms and Conditions.</a>In the
            event the General Terms and Conditions are updated, existing users
            may choose to discontinue using the products and services before the
            said update shall become effective, which is a minimum of two weeks
            after it has been announced.
          </p>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
          <ul className="d-flex m-0 p-0 list-unstyled">
            <li>
              <a className="active" href="/exchange/Inplay">
                <img src={CasinoIcon} />
                <span>Exchange</span>
              </a>
            </li>
            <li>
              <a href="/betby">
                <img src={SportsIcon1} />
                <span>Sportsbook</span>
              </a>
            </li>
            <li>
              <a
                className="homeactive"
                href="#"
                onClick={() => {
                  dispatch(handleRightSideBar(!showRightSideBar));
                }}
              >
                <img src={MenuIcon} />
                {/* <span>Menu</span> */}
              </a>
            </li>

            <li>
              <div onClick={handleSupportClick} className="SupportSection">
                <img src={SupportIcon1} />
                <span>Support</span>
              </div>
            </li>
            {!isApk ? (
              <li>
                <a href="#" className="headicons support ">
                  {/* <img src={AppDownload} /> */}

                  <GamesSearchModal />
                  <span>Search</span>
                </a>
              </li>
            ) : (
              <li>
                <a href="/casino/ezugi">
                  <img src={ezugi_icon} />
                  <span>Ezugi</span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </Container>

      <div className="copy-right">
        <p>
          Copyright © 2024 <span>Xcasino</span> | All rights reserved.
        </p>
      </div>
      <TawkChat ref={tawkMessengerRef} />
    </footer>
  );
};

export default Footer;
